import React from "react"
import { Link, graphql } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 1.45rem 1.0875rem;
  min-height: 100%;
`

const BlogTitle = styled.h1`
  font-family: "Lato";
`

const ArticleDate = styled.h5`
  font-family: "Aleo";
  display: inline;
  color: #606060;
`

const MarkerHeader = styled.h3`
  font-family: "Aleo";
  background: transparent;
  display: inline;
  transition: background 0.25s linear;
  border-radius: 0.1em;
  padding: 0 0.25em 0 0;

  &:hover {
    background: #7cf4d3;
  }
`

const ReadingTime = styled.h5`
  font-family: "Aleo";
  display: inline;
  color: #606060;
`

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Blog" />
      <Content>
        <BlogTitle>Blog</BlogTitle>
        {data.allMarkdownRemark.edges
          .filter(({ node }) => {
            const rawDate = node.frontmatter.rawDate
            const date = new Date(rawDate)
            return date < new Date()
          })
          .map(({ node }) => (
            <div key={node.id}>
              <Link
                to={node.frontmatter.path}
                css={css`
                  text-decoration: none;
                  color: inherit;
                `}
              >
                <MarkerHeader>{node.frontmatter.title} </MarkerHeader>
                <div>
                  <ArticleDate>{node.frontmatter.date}</ArticleDate>
                  <ReadingTime> | {node.fields.readingTime.text}</ReadingTime>
                </div>
                <p>{node.excerpt}</p>
              </Link>
            </div>
          ))}
      </Content>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { eq: false } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
            rawDate: date
            path
          }
          fields {
            slug
            readingTime {
              text
              words
            }
          }
          excerpt
        }
      }
    }
  }
`
